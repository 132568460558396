<template>
  <div class="dynamicFiltersPane">
    <div class="pane-header">
      Динамические фильтры
    </div>
    <el-scrollbar
      :style="{height:'calc(100% - 72px)', width: '100%', overflowX: 'hidden'}"
      wrap-style="overflow-x:hidden;"
      view-class="scrollbar-view"
      class="el-scrollbar-style"
    >
      <el-collapse
        ref="collapseObjects"
        class="layersAccordion"
        v-model="opened"
      >
        <el-collapse-item
          v-for="layer in layers"
          :key="layer.guid"
          :name="layer.guid"
        >
          <template slot="title">
            <div class="layer-title-accordion" :style="{ width: (Array.isArray(layer.dynamicFilters) && layer.dynamicFilters.length === 0) ? '99%' : 'calc(100% - 92px)'}">
              {{ layer.name }}
            </div>
          </template>
          <div class="buttonsContainer">
            <div class="itemViewModeSelection">
              <el-tooltip content="Применить фильтры" placement="top">
                <el-button
                  size="mini"
                  @click="applyFilter(layer)"
                  class="smallButton"
                  type="text"
                  v-if="Array.isArray(layer.dynamicFilters) && layer.dynamicFilters.length > 0">Применить</el-button>
              </el-tooltip>
            </div>
          </div>
          <div v-if="Array.isArray(layer.dynamicFilters) && layer.dynamicFilters.length === 0" style="margin-left: 10px;">
            Динамические фильтры отсутсвуют
          </div>
          <div
            v-for="filter in layer.dynamicFilters"
            class="oneFilterContainer"
          >
            <div style="width: 100%">
              <div style="float: left">{{filter.label}}</div>
              <el-switch style="float: right;" v-model="filter.enable"></el-switch>
            </div>
            <br>
            <component
              :is="filterTypes[filter.type].component"
              :filter="filter"
            ></component>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-scrollbar>
  </div>
</template>

<script>
  import StringFilter from './Filters/StringFilter.vue'
  import NumberFilter from './Filters/NumberFilter.vue'
  import BooleanFilter from './Filters/BooleanFilter.vue'
  import XrefFilter from './Filters/XrefFilter.vue'
  import MultiXrefFilter from './Filters/MultiXrefFilter.vue'
  import AddressFilter from './Filters/AddressFilter.vue'
  import DateFilter from './Filters/DateFilter.vue'
  import DateTimeFilter from './Filters/DateTimeFilter.vue'

  const FILTER_TYPES = {
    // строковое
    string_field: { component: 'StringFilter' },
    text_field: { component: 'StringFilter' },
    // числовое
    integer_field: { component: 'NumberFilter' },
    float_field: { component: 'NumberFilter' },
    // ссылки
    xref_field: { component: 'XrefFilter' },
    xref_multi_field: { component: 'XrefFilter' },
    // логическое
    boolean_field: { component: 'BooleanFilter' },
    // адреса
    address_field: { component: 'AddressFilter' },
    address_multi_field: {},
    // дата и время
    date_field: { component: 'DateFilter' },
    datetime_field: { component: 'DateTimeFilter'}
  }

  export default {
    name: 'DynamicFiltersPane',
    components: { StringFilter, NumberFilter, BooleanFilter, XrefFilter, MultiXrefFilter, AddressFilter, DateFilter, DateTimeFilter },
    props: {
      activeLayers: {
        type: Array,
        default: () => {
          return []
        }
      },
      activeLayersLength: {
        type: Number,
        default: 0
      }
    },
    data: function () {
      return {
        layers: [],
        loadingLayers: null,
        opened: [],
        xrefOptions: {},
        filterTypes: FILTER_TYPES
      }
    },
    watch: {
      activeLayersLength: {
        handler: async function (newValue, oldValue) {
          if (this.loadingLayers - 1 === newValue) {
            // пропускаем если выключается слой который фильтруем
            // следующее изменение выключит и включит нужный слой сразу
            return
          }
          await this.activeLayersChangeHandler(this.activeLayers)
          this.$emit('change-loading', false)
        }
      },
    },
    mounted: function () {

    },
    methods: {
      async activeLayersChangeHandler (activeLayers) {
        let newLayers = activeLayers.filter((nv) => {return this.layers.every((ov) => { return ov.guid !== nv.guid })})
        let missingLayers = this.layers.filter((ov) => { return activeLayers.every((nv) => { return nv.guid !== ov.guid })})
        await this.addLayers(newLayers)
        this.removeLayers(missingLayers)
      },
      removeLayers (removeLayers) {
        let me = this
        removeLayers.forEach((layer) => {
          me.layers.splice(me.layers.findIndex((l) => {return layer.guid === l.guid}), 1)
        })
      },
      async addLayers (newLayers) {
        for (let i = 0; i < newLayers.length; i++) {
          this.layers.splice(this.layers.length, 0, newLayers[i])
        }
      },
      applyFilter (layer) {
        this.loadingLayers = this.activeLayersLength
        this.$emit('change-loading', true)
        this.$emit('apply-filter', layer)
      }
    }
  }
</script>

<style>
  .dynamic-filter-condition {
    width: 40%;
    min-width: 100px;
  }
  .dynamic-filter-value {
    width: 60%;
    max-width: 270px;
  }
</style>
<style scoped>
  .scrollbar-view {
    width: calc(100% - 10px);
  }
  .el-scrollbar-style /deep/ .el-scrollbar__view {
    width: calc(100% - 10px);
  }
  .pane-header {
    padding: 10px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    flex-grow: 50;
  }
  .dynamicFiltersPane {
    width: 100%;
    height: 100%;
  }
  .layersAccordion {
    margin: 10px;
  }
  .layersAccordion /deep/ .el-collapse-item__content {
    border-left-color: #A5AEB6;
    border-left-style: solid;
    border-left-width: 1px;
    padding-bottom: 0;
  }
  .buttonsContainer {
    position: relative;
    top: -30px;
  }
  .el-collapse /deep/ .el-collapse-item__wrap {
    border: none;
    overflow: visible;
  }
  .smallButton {
    float: right;
    padding-top: 5px;
  }
  .el-collapse {
    border: none;
  }
  .el-collapse /deep/ .el-collapse-item__wrap {
    border: none;
    overflow: visible;
  }
  .el-collapse /deep/ .el-collapse-item__arrow {
    left: -26px;
    position: absolute;
  }
  .el-collapse /deep/ .el-collapse-item__header {
    left: 20px;
    position: relative;
    height: 36px;
    line-height: 36px;
    border: none;
    white-space: nowrap;
    font-weight: 400;
  }
  .layer-title-accordion {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
  }
  .oneFilterContainer {
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .itemViewModeSelection {
    float: right;
    width: max-content;
    height: 24px;
    position: absolute;
    right: 0;
  }
</style>
<style>
  .map-dynamic-filter {
    display: inline-block;
  }
</style>
